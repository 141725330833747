import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import { AdStarBlock, Category, GeneralSettings } from '../../types'
import { IMenu } from '../../graphql/entities/Menu'
import TopBanner from '../../components/Banners/TopBanner/TopBanner'
import Content from '../../components/Content/Content'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import AwardsHeader from '../../components/MuddyAwards/AwardsHeader'
import AwardsMenu from '../../components/MuddyAwards/AwardsMenu'
import Breadcrumb from '../../components/organisms/breadcrumb/Breadcrumb'
import PageMeta from '../../components/PageMeta/PageMeta'
import { AWARDS_COUNTY_INFO } from '../../lib/data/Awards'
import Leaderboard from '../../components/Banners/Leaderboard/Leaderboard'
import { AwardCat } from './live-results'
import {
  AwardsStateComparison,
  MuddyAwardsContext,
  MuddyAwardsStates
} from '../../lib/utils'

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      awardsCategories {
        title
        image
        description
        id
        finalists {
          meta_a
          meta_b
          meta_c
          meta_d
          meta_e
          winning_finalist
        }
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`

interface AwardsFAQProps {
  data: {
    wpgraphql: {
      generalSettings: GeneralSettings
      adStarCodes: AdStarBlock[]
      awardsMenu: {
        menuItems: {
          nodes: IMenu[]
        }
      }
      awardsCategories: AwardCat[]
      category: Category
    }
  }
}

const AwardsWinners = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      awardsCategories,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      },
      category
    }
  }
}: AwardsFAQProps) => {
  const AwardsContext = useContext(MuddyAwardsContext)
  const countyInfo = generalSettings.siteId
    ? AWARDS_COUNTY_INFO.filter(c => c.siteId === generalSettings.siteId)[0]
    : AWARDS_COUNTY_INFO[0]

  if (generalSettings.siteId === 1) return null

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Muddy Stilettos Awards 2024',
          description:
            'The Muddy Stilettos Awards are the most coveted awards for independent lifestyle businesses across 28 counties, and totally FREE to enter!',
          image:
            'https://muddystilettos.co.uk/images/awards/Awards_Badge_2024.gif'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      <Leaderboard adSlot={'TopLB'} />
      {category && (
        <AwardsHeader category={category} generalSettings={generalSettings} />
      )}
      {subMenu && (
        <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      )}
      <div>
        <Content>
          <Breadcrumb
            postType={`awards`}
            postTitle={'/ Winners'}
            postURI={'/awards/winners'}
          />
          <h1
            dangerouslySetInnerHTML={{
              __html: 'Meet your Muddy Awards 2024 Winners!'
            }}
          />

          <p>
            Well, that was FUN! The cocktail glasses were overflowing as the
            Muddy Stilettos Awards 2024 romped to a close last week, having
            reached a mind-blowing 10 million people nationally over 8 weeks and
            515,000 votes cast. I know, bonkers!
          </p>

          <p>
            Now in their 11th year, we&apos;ve always proudly run the Muddy
            Awards - this year{' '}
            {countyInfo.sponsoredby && (
              <>
                supported by{' '}
                <a
                  href={countyInfo.sponsorUrl}
                  target="_BLANK"
                  rel="noreferrer"
                >
                  {countyInfo.sponsoredby}
                </a>{' '}
                and{' '}
              </>
            )}
            sponsored nationally by{' '}
            <a
              href="https://www.tide.co/offers/muddystilettosawards/"
              target="_BLANK"
              rel="noreferrer"
            >
              Tide
            </a>{' '}
            - on the basis of being free for all businesses to take part and
            it&apos;s still as thrilling as ever to shine a light on brilliant
            lifestyle indies that make our lives so much more fun and vibrant.
          </p>

          <p>
            A whopping great <strong>THANK YOU</strong> to all our Muddy readers
            who took part or voted - the Muddy Awards are nothing without you!
            OK enough waffling, the Muddy Awards 2024 winners in{' '}
            <strong>{countyInfo.county}</strong> are...
          </p>

          {AwardsStateComparison(
            AwardsContext,
            MuddyAwardsStates.WINNERS,
            '>='
          ) ? (
            <>
              {awardsCategories.map((cat, i) => (
                <div key={i} style={{ marginBottom: '3em' }}>
                  <h2
                    style={{
                      textTransform: 'uppercase',
                      color: 'rgb(231, 44, 94)'
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `${cat.title
                        .replace('Best ', '')
                        .replace(' / ', '/')
                        .replace('/', ' / ')}${i === 0 ? ' Venue' : ''}`
                    }}
                  />
                  {cat.finalists?.winning_finalist && (
                    <p>
                      <strong>{cat.finalists?.winning_finalist}</strong>
                    </p>
                  )}
                </div>
              ))}
            </>
          ) : (
            <p>To be announced soon!</p>
          )}
        </Content>
      </div>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default AwardsWinners
